import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SpinnerBase } from '../assets/no-stats.svg';

const SpinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  animation: ${SpinnerAnimation} 1.2s infinite;
  animation-timing-function: linear;
  opacity: 0.8;
  margin-top: 13px;
`;

const Spinner = () => (
  <Wrapper>
    <SpinnerBase />
  </Wrapper>
);

export default Spinner;
