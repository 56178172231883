import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import YourComponent from './components/YourComponent';

function App() {
    return (
      <div className="App h-100">
        <BrowserRouter basename={'/'}>
          <Routes>
            <Route path="/" element={<YourComponent />} />
            <Route path="/:location" element={<YourComponent />} />
          </Routes>
        </BrowserRouter>
      </div>
    )
  }

export default App;
