import { Button, Modal } from 'react-bootstrap';
import { Link, useNavigate, useParams,BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import usePrevious from "../hooks/usePrevious";
  import monitor, { ImageValidity, DeviceOrientation, SessionState, HealthMonitorCodes } from '@binah/web-sdk'; // Import the Binah SDK
import Webcam from 'react-webcam';
import StartButton from "../components/StartButton";
import Swal from 'sweetalert2';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';

var key = CryptoJS.enc.Hex.parse("SravrVJy1pxBs7DRyZhoLE1hkJKfF3SZ");
var iv = CryptoJS.enc.Hex.parse("SravrVJy1pxBs7DR");

const YourComponent = () => {
  
  const navigate = useNavigate();
  const {location} = useParams();

  const stringArray = location.split('_');
  const uuid = stringArray[0];
  const patient_id = stringArray[1];

  const [showMessage, setShowMessage] = useState("Stay still for accurate results");
  const [session, setSession] = useState();
  const webcamRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [sessionState, setSessionState] = useState(null);
  const [vitalSigns, setVitalSigns] = useState(null);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [startMeasuring, setStartMeasuring] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMonitorReady, setIsMonitorReady] = useState(false);
  const [enabledVitalSigns, setEnabledVitalSigns] = useState();
  const [offlineMeasurements, setOfflineMeasurements] = useState();
  const prevSessionState = usePrevious(sessionState);
  let processingTime = 60;
  var monitorInitialized = false;
  const notify = (msg) => toast(msg);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    if(startMeasuring){
      const totalTime = 60; // Total time in seconds
      const updateInterval = 100; // Update interval in milliseconds
      const steps = totalTime / (updateInterval / 1000);

      let currentStep = 0;

      const intervalId = setInterval(() => {
        currentStep += 1;
        const newProgress = parseFloat((currentStep / steps) * 100).toFixed(2);

        if(newProgress <= 10){
          setShowMessage("Stay still for accurate results")
        } else if (newProgress >= 10 && newProgress <= 20){
          setShowMessage("Avoid moving or talking")
        } else if (newProgress >= 20 && newProgress <= 30){
          setShowMessage("Stay still for accurate results")
        } else if (newProgress >= 30 && newProgress <= 40){
          setShowMessage("Wait until the end for the best results")
        } else if (newProgress >= 40 && newProgress <= 50){
          setShowMessage("Results in a few seconds, hold on")
        } else if (newProgress >= 50 && newProgress <= 60){
          setShowMessage("Avoid distractions and stay focused")
        } else if (newProgress >= 60 && newProgress <= 70){
          setShowMessage("Patience is key for accurate results")
        } else if (newProgress >= 70 && newProgress <= 80){
          setShowMessage("Hold Still, just a little bit longer")
        } else if (newProgress >= 80 && newProgress <= 90){
          setShowMessage("Almost done")
        } else {
          setShowMessage("Hold on, all the results will appear in a few seconds...")
        }
        setProgress(newProgress); 

        if (currentStep >= steps) {
          clearInterval(intervalId);
        }
      }, updateInterval);

      return () => clearInterval(intervalId);
    }
  }, [startMeasuring]);

  const openModal = () => {
    setShowModal(true);
  };
  
  const closeModal = useCallback(() => {
    // notify('started')
    setShowModal(false);
    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
    } else if (isMeasuring()) {
      setStartMeasuring(false);
    }
  }, [sessionState, setIsLoading, processingTime]);

  const updateVitalSigns = useCallback((newVitalSigns) => {
    setVitalSigns((prevVitalSigns) => ({
      ...prevVitalSigns,
      ...newVitalSigns,
    }));
    
  }, []);


  const onVitalSign = useCallback((vitalSign) => {
    console.log({vitalSign})
    updateVitalSigns(vitalSign);
  }, [updateVitalSigns]);



  const onFinalResults = useCallback((vitalSignsResults) => {
    console.log('vitalSignsResults=>',{vitalSignsResults})
    BinahApiCall(vitalSignsResults.results);
    updateVitalSigns(vitalSignsResults.results);
    // notify('heartRate stringify =>',JSON.stringify(vitalSignsResults.results))
    // notify('heartRate=>',JSON.stringify(vitalSignsResults.results[0]))
  }, [updateVitalSigns,progress]);

  const BinahApiCall = (vitalSignsObj) => {
    
    console.log("binah apin call",uuid);
    const binahObj = {
      "uuid" : uuid,
      "patient_id" : patient_id,
      "vital_signs" : vitalSignsObj
    }
   
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading()
      }
    })
    
    let data = bodyEncryption(binahObj,true);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://monteserenohealth.org:8082/api/v1/isabel/add-binah-vital-signs',
      headers: { 
        'api-key': 'X2BKGjwEWj+kiKWhBiJ0yQ==', 
        'Accept-Language': 'en', 
        'Content-Type': 'text/plain'
      },
      data : data
    };

    axios.request(config)
    .then((res) => {
      Swal.close()
      window.location.href = `https://monteserenohealth.org/patient/report-generate/${uuid}`
    })
    .catch((error) => {
      console.log(error);
      Swal.fire({
        position: "top-right",
        icon: "error",
        title: "Something went wrong",
        toast: true,
        showConfirmButton: false,
        timer: 2000
    });
    });
    
}

function bodyEncryption(request, isStringify)
{
    var request = (isStringify) ? JSON.stringify(request) : request;
    var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
    return encrypted.toString();
}

function bodyDecryption(request)
{
    var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

  const onError = (errorData) => {
    console.log({errorData})
    setError(errorData);
  };

  const onWarning = (warningData) => {
    console.log({warningData})
    // notify(JSON.stringify(warningData))
    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING) {
      setVitalSigns(null);
    }

    if (warningData.code === HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING) {
      console.log('unsupported orientation',warningData.code)
    } else {
      setWarning(warningData);
    }
  };

  const onImageData = useCallback((imageValidity) => {
    let message;

    if (imageValidity !== ImageValidity.VALID) {
      switch (imageValidity) {
        case ImageValidity.INVALID_DEVICE_ORIENTATION:
          message = 'Unsupported Orientation';
          break;
        case ImageValidity.TILTED_HEAD:
          message = 'Head Tilted';
          break;
        case ImageValidity.FACE_TOO_FAR: // Placeholder, currently not supported
          message = 'You Are Too Far';
          break;
        case ImageValidity.UNEVEN_LIGHT:
          message = 'Uneven Lighting';
          break;
        case ImageValidity.INVALID_ROI:
        default:
          message = 'Face Not Detected';
      }
      console.log('imageValidity', message)
      // notify(message)
      setErrorMsg(message)
    } else {
      setErrorMsg('')
    }
  }, []);

  const healthMonitorSessionMode = () => {
    return {FACE_FRONT_CAMERA : 1}
  }

  const onStateChange = useCallback((state) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
      (offlineMeasurements) => {
        // notify(JSON.stringify(offlineMeasurements))
        setOfflineMeasurements(offlineMeasurements);
      },
      [],
  );
  const onActivation = useCallback(
    (activationId) => {
      console.log({activationId})
      
      
    },
    []);

  useEffect(() => {
    (async () => {
      if(isMobile){
        try {
          if (monitorInitialized) {
            return;
          }
          monitorInitialized = true;
          await monitor.initialize({
            licenseKey: '4B30D7-C110BD-4DCDB6-4D3745-FAAE99-C8DF3B',
            licenseInfo: {
              onEnabledVitalSigns,
              onOfflineMeasurement,
              onActivation,
            }
          });
          console.log(`Initialized monitor`);
          notify('Initialized monitor');
          setIsMonitorReady(true);
          setShowModal(true)
          setError({ code: -1 });
        } catch (e) {
          console.error('Error initializing HealthMonitor', e);
          setIsMonitorReady(false);
          setError({ code: e.errorCode });
        }
      }else{
        setShowModal(false);
      }
      
    })();
}, []);

  useEffect(() => {
    (async () => {
      
      try {
        if (!isMonitorReady || !processingTime || !webcamRef.current.video) {
          return;
        }
        sessionState === SessionState.ACTIVE && session.terminate();

        const devices = await navigator.mediaDevices.enumerateDevices();

        const cameraDevices = devices.filter(device => device.kind === 'videoinput');
        const cameraDeviceId = cameraDevices.length > 0 ? cameraDevices[0].deviceId : '';

        const options = {
          input: webcamRef.current.video,
          cameraDeviceId: cameraDeviceId,
          processingTime,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
          onImageData,
          mode: healthMonitorSessionMode
        };

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`, faceSession);
        setSession(faceSession);
        setError({ code: -1 });
      } catch (e) {
        setError({ code: e.errorCode });
        console.error('Error creating a session', e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  useEffect(() => {
    if(!isMonitorReady){
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
            Swal.showLoading()
        }
      })
    } else {
      Swal.close()
    }
  },[showModal])

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
    }
    if (
        sessionState === SessionState.ACTIVE &&
        prevSessionState !== sessionState
    ) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [sessionState]);

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  const isMeasuring = useCallback(
      () => sessionState === SessionState.MEASURING,
      [sessionState],
  );
 
  const videoConstraints = {
    width: window.innerWidth,
    height: window.innerHeight/2,
    facingMode: "user"
  };

  return (
    <>
    {showModal && (
      <Modal show={openModal} onHide={closeModal} centered id="modal-emergency" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-custom">
            <div className="modal-header border-0">
              <h5 className="modal-title text-center" id="staticBackdropLabel"><i
                className="fa-solid fa-circle-exclamation pe-2"></i>Instructions</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}> </button>
            </div>
            <div className="modal-body pb-0">
              <div className="d-flex justify-content-between">
                <div className=" text-center d-flex display-column justify-content-center align-items-center">
                  <div className="instruct-detail">
                    <img src={process.env.PUBLIC_URL + "/assets/images/trevel_17.jpg"} alt="" />
                  </div>
                  <p className="mb-0 font_500 font_18 pt-2">No Hat</p>
                </div>
                <div className="text-center d-flex display-column justify-content-center align-items-center">
                  <div className="instruct-detail">
                    <img src={process.env.PUBLIC_URL + "/assets/images/glasses_ban.jpg"} alt="" />
                  </div>
                  <p className="mb-0 font_500 font_18 pt-2">No Glasses</p>
                </div>
                <div className="text-center d-flex display-column justify-content-center align-items-center">
                  <div className="instruct-detail">
                    <img src={process.env.PUBLIC_URL + "/assets/images/no_mask.jpg"} alt="" />
                  </div>
                  <p className="mb-0 font_500 font_18 pt-2">No Mask</p>
                </div>
                <div className="text-center d-flex display-column justify-content-center align-items-center">
                  <div className="instruct-detail well-lit">
                    <img src={process.env.PUBLIC_URL + "/assets/images/bulb.svg"} alt="" />
                  </div>
                  <p className="mb-0 font_500 font_18 pt-2">Well-lit</p>
                </div>
              </div>
            </div>
            <p className="font_18 text-center pt-4">Please remove your glasses, do not wear a mask or hat, stand in
              a
              well-it area.
              Press the start
              button to read the vitals.</p>
            <div className="modal-footer border-0">
              <button type="button" className="btn modal-button font_500" data-bs-dismiss="modal" onClick={closeModal}> Start Vitals
                Scan</button>



            </div>
          </div>
        </div>
      </Modal>
    )}
    {isMobile && (
    <main className="msh--main h-100">
      <div className="container-fluid h-100">
        <div className="row align-items-center justify-content-center h-100">
          <div className="col-xl-5 col-lg-5 col-md-8 col-10 p-0 text-center">
              {errorMsg && <div className="alert-msg mb-3">{errorMsg}</div>}
              <div className="overlay"></div>
          </div>
          <div className="col-12">
            <div className="webcam-container">
              <Webcam
                ref={webcamRef}
                id="video"
                audio={false}
                height={videoConstraints.height}
                screenshotFormat="image/jpeg"
                width={videoConstraints.width}
                className="webcam-video"
                videoConstraints={videoConstraints}
              />
            </div>
            {/* <Webcam
              ref={webcamRef}
              id="video"
              audio={false}
              height={videoConstraints.height}
              screenshotFormat="image/jpeg"
              width={videoConstraints.width}
              videoConstraints={videoConstraints}
              style={{position: "absolute", top: 0, left: 0}}
            /> */}

          </div>
          <div className="col-xl-5 col-lg-5 col-md-8 col-10 p-0 text-center">
            <div className="position-relative binah-card">
               <div className="row align-items-center justify-content-center h-100">
                  <div className="col-12">
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <div className="heartbpm">
                        <img src={process.env.PUBLIC_URL + "/assets/images/icons8-heart-48.png"} alt="" />
                      </div>
                      <p className="font_20 mb-0 font_500">
                        {vitalSigns != null && vitalSigns.heartRate!=undefined
                          ? vitalSigns.heartRate.value || '--'
                          : 'N/A'} <sub className="font_10 font_400">BPM</sub><br></br>
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-3">
                    <p className="font_20 mb-0 heartbpm font_500">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/lungs.svg"} alt="" />
                      {vitalSigns != null && vitalSigns.breathingRate!=undefined
                        ? vitalSigns.breathingRate.value || '--'
                        : 'N/A'} <sub className="font_10 font_400">BRPM</sub><br></br>
                    </p>
                  </div>
                  <div className="col-3">
                    <p className="font_20 mb-0 heartbpm font_500">
                      <img src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"} alt="" />
                      {vitalSigns != null && vitalSigns.wellnessLevel !=undefined
                        ? vitalSigns.wellnessLevel.value + " Level" || '--'
                        : 'N/A'} <sub className="font_10 font_400">SL</sub><br></br>
                    </p>
                  </div>
                  <div className="col-3">
                  <div className='heartbpm'>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/cells.svg"} alt="" />
                  </div>
                 
                    <p className="font_20 mb-0  font_500">
                      
                      {vitalSigns != null && vitalSigns.hemoglobin!=undefined
                        ? vitalSigns.hemoglobin.value || '--'
                        : 'N/A'} <sub className="font_10 font_400">HGB</sub><br></br>
                    </p>
                  </div> */}
                </div>
                <p className="pt-3 text-gray font_500 mb-2 font_16">{showMessage}</p>
                <img src={process.env.PUBLIC_URL + "/assets/images/heartbeat-5510281-4584163.gif"} className="vital-icon" alt="" />
                {isMonitorReady ? <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}%
                  </div>
                </div> : ""}
              
            </div>
          </div>
        </div>
      </div>
    </main>
    )}
     {!isMobile && (
        // Display a message for desktop users
        <div>
          <p className="font_34 font_700">Please open this page on a mobile browser for the best experience.</p>
        </div>
      )}
      <div>
        <ToastContainer />
      </div>
  </>
  );
};

export default YourComponent;
